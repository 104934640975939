import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import BlogContent from "./components/storyblok/BlogContent";



import { storyblokInit, apiPlugin } from "@storyblok/react";
//console.log("api key");
//console.log(process.env.REACT_APP_KEY);
storyblokInit({
  //accessToken: process.env.REACT_APP_KEY,
  accessToken: "oWPZmxX5gr3KaRrXuRR5Tgtt",
  use: [apiPlugin],
  components: {
    Page:BlogContent
  },
});
const container = document.getElementById("app");
const root = createRoot(container);
root.render(
    <App />
);

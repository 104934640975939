import React from "react";
import { useState, useEffect } from "react";
import "./Home.css";
import BlogCard from "../components/BlogCard";
import MainHeading from "../components/MainHeading";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import getFDate from "../../utils/getdate";
import Footer from "../components/Footer";
import TopNavigation from "../components/TopNavigation";
import { Helmet } from "react-helmet";

function Home() {
  const [blogdata, setblogdata] = useState([]);
  async function fetchblog() {
    try {
      const resp = await axios.get(
        "https://api.storyblok.com/v2/cdn/stories/",
        {
          params: {
            //token: process.env.REACT_APP_KEY,
            token: "oWPZmxX5gr3KaRrXuRR5Tgtt",
            version: "draft",
            sort_by: "first_published_at:desc",
          },
        }
      );
      const data = await resp.data;
      const blogs = await data.stories;
      console.log(data);
      blogs.forEach((element) => {
        element.first_published_at = getFDate(element.first_published_at);
      });
      setblogdata(blogs);
    } catch (error) {
      console.log(error);
      setblogdata([]);
    }
  }
  useEffect(() => {
    fetchblog();
  }, []);
  console.log(blogdata);
  return (
    <div className="App">
      <Helmet>
        <title>Finish Factory Blog</title>
        <meta name="description" content="Blog page of Finish Factory" />
      </Helmet>
      <TopNavigation />
      <MainHeading />

      <div style={{minHeight:'60vh'}}>
        {blogdata.map((element) => (
          <BlogCard key={element.full_slug} data={element} />
        ))}
      </div>
      <Footer />
    </div>
  );
}

export default Home;

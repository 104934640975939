const getFDate = (str) => {
    if(!str) return "draft"
    const string = str.slice(0, 10);
    const d = string.split("-");
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[d[1] - 1] + " " + d[2] + ", " + d[0];
  };

export default getFDate
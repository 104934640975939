import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Stack from "react-bootstrap/Stack";
import DotIcon from "../../assets/author_dot.svg";
import { Link } from "react-router-dom/dist";
import "./Cards.css";

function BlogCard(props) {
  return (
    <div className="container">
      <div className="cardBodyContainer p-4 my-5 card">
        <Card.Img
          className="card-image-img"
          src={props.data.content?.image?.filename}
        />
        <div className="cardBodyContent">
          <div>
            <Card.Title className="text-start textBold px-md-3 py-2">
              {props.data.content?.title}
            </Card.Title>
            <Stack direction="horizontal">
              <p className="ms-md-3 blog-date__card">
                {props?.data?.first_published_at}
              </p>
              <img
                src={DotIcon}
                className="mx-2 mb-3"
                style={{
                  width: "4px",
                  height: "4px",
                }}
              />
              <Button
                style={{
                  textAlign: "center",
                  color: "#000",
                  backgroundColor: "#C1CAE3",
                  fontWeight: 500,
                  border: "1px solid #C1CAE3",
                  padding: "2px 12px",
                }}
                className="mb-3"
              >
                {" "}
                {props.data?.content.tag}{" "}
              </Button>
            </Stack>
          </div>

          <Card.Text className="text-start py-3 px-md-3">
            {props.data.content?.summary}
          </Card.Text>

          <div className="categoryLabel">
            <p className="blog-author__card pt-3">
              {props.data.content?.author}
            </p>
            <Link to={`/blog/${props.data?.slug}`}>
              <Button
                style={{
                  color: "#f24337",
                  fontWeight: 700,
                  backgroundColor: "white",
                  border: "none",
                }}
              >
                {" "}
                {"Read more >"}{" "}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogCard;

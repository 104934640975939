import HeaderLogo from "../../assets/brand-black.svg";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import "./TopNavigation.css";

const TopNavigation = () => {
  return (
    <Navbar expand="lg ">
      <Container>
        <Navbar.Brand className="" href="https://www.finishfactory.in">
          <img id="site-logo" src={HeaderLogo} alt="" srcSet="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="ms-auto text-center my-2 my-lg-0" navbarScroll>
            <Nav.Link className=" nav-items font-link" href="https://www.finishfactory.in">
              Home
            </Nav.Link>
            <Nav.Link className="nav-items font-link" href="https://www.finishfactory.in/packages.html">
              Packages
            </Nav.Link>
            <Nav.Link className="nav-items font-link" href="https://www.finishfactory.in/services.html">
              Services
            </Nav.Link>
            <Nav.Link
              className="nav-items font-link"
              href="#"
              style={{
                fontWeight: "800",
              }}
            >
              Blogs
            </Nav.Link>
            <Nav.Link className="nav-items font-link me-0" href="https://www.finishfactory.in/about-us.html">
              About Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default TopNavigation;

import Card from "react-bootstrap/Card";
import Stack from "react-bootstrap/Stack";
import './Cards.css';


const readingTime = (str)=>{
  const wpm = 225;
  const words = str.trim().split(/\s+/).length;
  const time = Math.ceil(words / wpm);
  return time;
}

const Related = ( {data} ) => {
  return (
      <Card className="related-cards" style={{marginBottom:'1.875rem'}}>
        <div className="p-4">
          <Card.Img variant="top" src={data.content?.image?.filename} className="related__card-img"/>
          <Card.Body>
            <Card.Title style={{fontSize:'1.125rem',color:'#220756', fontWeight:800,}}>{data.content?.title}</Card.Title>
            <Card.Text style={{textAlign:'justify',fontSize:'1rem',color:'#666666',}}>
            {data.first_published_at}
            </Card.Text>
            <Card.Text style={{textAlign:'justify',fontSize:'1rem',color:'#666666',}}>
            {data.content?.summary}
            </Card.Text>       
          </Card.Body>
        </div>
        <Stack direction="horizontal" className="mt-auto justify-content-between" style={{fontSize:'1rem', padding:'0 2.5rem 1.875rem',}}>
              <Card.Link
                href={`/blog/${data.slug}`}
                style={{ color: "#EA2B7B", textDecoration: "none", fontWeight:700,}}
                
              >
                Read more {">"}
              </Card.Link>
              <Card.Text style={{ color: "#666666",fontWeight:600, }}>{} min read</Card.Text>
            </Stack>
      </Card>
  );
};

export default Related;

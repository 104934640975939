import React from "react";
import { Stack } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const MainHeading = () => {
  return (
    <div className="mt-5 pt-5 container">
      <Stack direction='horizontal' className="justify-content-center">
      <h2
            className=""
            style={{
              fontWeight: 500,
              fontSize: "2.5rem",
              marginRight:'10px',
            }}
          >
            Read our
          </h2>
        <div className="position-relative">
          <h2
            className=""
            style={{
              fontWeight: 500,
              fontSize: "2.5rem",
            }}
          >
            Blogs
          </h2>
          <div className="highlight-text position-absolute start-0"></div>
        </div>
        {/* <p className='align-center p-5 mb-5'></p> */}
      </Stack>
    </div>
  );
};

export default MainHeading;

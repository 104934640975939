import Container from "react-bootstrap/Container";
import Divider from "../../assets/divider.svg";
import Related from "./Related";
import "../pages/BlogPage.css";
import { StoryblokComponent } from "@storyblok/react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Blog = ({data}) => {
  return (
    <Container className="d-flex flex-column align-items-center mx-auto" id="blog-body">
        <StoryblokComponent blok={data.cur.content} />
        {data.readmore && (
          <>
            <div className="d-flex justify-content-center my-5">
              <img
                src={Divider}
                alt=""
                srcSet=""
                style={{ width: "14.46rem" }}
              />
            </div>
            <h4 className="mb-4 align-self-start" style={{ fontWeight: 700 }}>
              Read more articles
            </h4>
            <Row>
              <Col lg={6} className='flex-col'>
                {" "}
                <Related data={data.nextblog1} />{" "}
              </Col>
              <Col lg={6} className='flex-col'>
                {" "}
                <Related data={data.nextblog2} />{" "}
              </Col>
            </Row>
          </>
        )}
    </Container>
  );
};

export default Blog;

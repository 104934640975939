import backBtn from "../../assets/leftarrow.svg";
import Stack from "react-bootstrap/Stack";
import BlogTitle from "./BlogTitle";
import parse from "html-react-parser";
import "./BlogContent.css"

const BlogContent = ({blok}) => {
  console.log(blok.renderedbody);
  return (
    <div>
      <Stack direction="horizontal" className="mt-4 mt-md-5">
        <a href="/" >
          <img
          className="back-btn"
          src={backBtn}
          alt=""
          />
        </a>  
        <BlogTitle data={blok.title}/>
      </Stack>

      <Stack direction="horizontal" className="mt-2">
      {/* <p className="ps-4 blog-author">{blok.Author}</p>
      <img src={DotIcon} className='mx-2' style={{width:'3px', height:'3px'}}/> */}
      <p className="ps-4 blog-date">{blok.first_published_at}</p>
      <a href="#" className="ms-3 blog-tag">{blok.tag}</a>
      </Stack>
      <img className="blog-thumbnail rounded-2" src={blok.image.filename} alt="" srcSet="" />
      
      <div id="storyblok-blog" className="blog-content" style={{ textAlign: "justify", fontSize:'1rem',fontWeight:'400',lineHeight:'1.5rem' }}>
        {parse(blok.renderedbody)}
      </div>
      <p className="mt-5 text-end blog-author">- By 
      <span style={{fontWeight:700,}}>  {blok.author}</span></p>
    </div>
  );
};



export default BlogContent;

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/pages/Home";
import BlogPage from "./components/pages/BlogPage";
import ErrorPage from "./components/pages/ErrorPage";
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/blog" exact element={<Home />} />
        <Route path="/:slug" exact element={<BlogPage />} />
        <Route path="/blog/:slug" exact element={<BlogPage />} />
        <Route path="*" exact element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

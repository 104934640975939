import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FooterLogo from "./../../assets/footer_brand.svg";
import "./Footer.css";

const Footer = () => {
  return (
    <Container fluid className="footer py-5 mt-5">
      <Container className="mt-5">
        <Row className="text-white">
          <Col md={3} className="flex-column justify-content-md-start align-items-center align-items-md-start">
            <img src={FooterLogo} />
            <p className="text-white mb-0">
              Your one stop shop for All Finishing Works.
            </p>
          </Col>
          <Col sm={12} md={2} className=" mt-5  my-2 my-md-0">
            <div>
              <h4>I'm looking for</h4>
              <a className="text-white d-block" href="https://www.finishfactory.in/packages.html">
                Packages
              </a>
              <a className="text-white d-block" href="https://www.finishfactory.in/services.html">
                Services
              </a>
            </div>
          </Col>
          <Col sm={12} md={2} className=" my-2 my-md-0">
            <div>
              <h4>Company</h4>
              <a className="text-white d-block" href="https://www.finishfactory.in/about-us.html">
                About
              </a>
              <a className="text-white d-block" href="#">
                Blogs
              </a>
            </div>
          </Col>
          <Col sm={12} md={2} className=" my-2 my-md-0">
            <div>
              <h4>Follow / Connect</h4>
              <a className="text-white d-block" href="https://www.facebook.com/thefinishfactory">
                Facebook
              </a>
              <a className="text-white d-block" href="https://www.instagram.com/finishfactory/">
                Instagram
              </a>
              <a className="text-white d-block" href="https://www.linkedin.com/company/thefinishfactory/about/">
                Linkedin
              </a>
              <a className="text-white d-block" href="https://api.whatsapp.com/send?phone=918086021101">
                Whatsapp
              </a>
            </div>
          </Col>
          <Col sm={12} md={3} className="text-center my-2 my-md-0">
            E1, Fact Nagar, Mini Bypass Road, Tripunithura, Kochi +91 80861
            86101 , +91 80860 21101 sales@finishfactory.in
          </Col>
        </Row>
          <div className="white-line" />
          <span className="text-white">
            2023 Finish Factory. All right reserved.
          </span>
      </Container>
    </Container>
  );
};

export default Footer;

import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./BlogPage.css";
import TopNavigation from "../components/TopNavigation";
import Blog from "../components/Blog";
import Footer from "../components/Footer";
import getFDate from "../../utils/getdate";
import { renderRichText } from "@storyblok/react";
import { Helmet } from "react-helmet";

function BlogPage() {
  const params = useParams();
  const slug = params.slug;
  const [blogdata, setblogdata] = useState(null);

  const apiParams = {
    token: "oWPZmxX5gr3KaRrXuRR5Tgtt",
    version: "draft",
  };

  async function fetchblog() {
    try {
      const resp = await axios.get(
        `https://api.storyblok.com/v2/cdn/stories/${slug}`,
        { params: apiParams }
      );
      const data = await resp.data;
      const blog = await data.story;
      console.log(`blog data : ${blog}`);
      blog.content.first_published_at = getFDate(blog.first_published_at);
      blog.content.renderedbody = renderRichText(blog.content.desc);
      const fulldata = {};
      fulldata.cur = blog; //adding cur property
      console.log(fulldata);

      const nextBlogRespo = await axios.get(
        `https://api.storyblok.com/v2/cdn/stories/`,
        { params: { ...apiParams, excluding_slugs: `${slug}` } }
      );
      const nextblogs = await nextBlogRespo.data.stories;
      if (nextblogs.length < 2) {
        fulldata.readmore = false;
      } else if (nextblogs.length === 2) {
        nextblogs[0].first_published_at = getFDate(
          nextblogs[0].first_published_at
        );
        nextblogs[1].first_published_at = getFDate(
          nextblogs[1].first_published_at
        );
        fulldata.readmore = true;
        fulldata.nextblog1 = nextblogs[0];
        fulldata.nextblog2 = nextblogs[1];
      } else {
        const nextb1 = Math.floor(Math.random() * nextblogs.length);
        const nextb2 = Math.floor(Math.random() * nextblogs.length);
        let nextblog1;
        let nextblog2;
        if (nextb1 === nextb2) {
          if (nextb1 === 0) {
            nextblog1 = nextblogs[nextb1];
            nextblog2 = nextblogs[nextb1 + 1];
          } else {
            nextblog1 = nextblogs[nextb1];
            nextblog2 = nextblogs[nextb1 - 1];
          }
        } else {
          nextblog1 = nextblogs[nextb1];
          nextblog2 = nextblogs[nextb2];
        }
        nextblog1.first_published_at = getFDate(nextblog1.first_published_at);
        nextblog2.first_published_at = getFDate(nextblog2.first_published_at);
        fulldata.readmore = true;
        fulldata.nextblog1 = nextblog1;
        fulldata.nextblog2 = nextblog2;
      }

      setblogdata(fulldata);
    } catch (error) {
      console.log(error);
      setblogdata("error");
    }
  }
  useEffect(() => {
    fetchblog();
  }, []);
  // console.log(blogdata);
  if (!blogdata) {
    return <> </>;
  } else if (blogdata === "error") {
    return <h1>Page Not Found</h1>;
  } else {
    return (
      <>
        <div className="main-app">
          <Helmet>
            <title>{blogdata.cur.content.title}</title>
            <meta name="description" content={blogdata.cur.content.metadata} />
          </Helmet>
          <TopNavigation />
          {console.log(`Blog data : `, blogdata)}
          <Blog data={blogdata} />
          <Footer />
        </div>
      </>
    );
  }
}

export default BlogPage;
